.privacy-policy-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  overflow-x: hidden;
  width: 100%;
  padding: 0;
}

.privacy-policy-container {
  flex-grow: 1;
  background-color: white;
  width: 100%;
  margin: 0 auto;
  padding: 100px;
  padding-top: 100px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #666;
}

.privacy-policy-container h1 {
  text-align: center;
  background-image: linear-gradient(
    135deg,
    #6d0fb5 0%,
    #a12fbd 25%,
    #e12b42 50%,
    #b74f72 75%,
    #51239f 100%
  );
  background-size: 400% 100%;
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  animation: textFillMove 3s linear infinite;
  font-size: 32px;
  margin-bottom: 10px;
  font-weight: 600;
}

.privacy-policy-container .effective-date {
  font-style: italic;
  color: #666;
  text-align: center;
  margin-bottom: 40px;
}

.privacy-policy-container h2 {
  color: #333;
  font-size: 24px;
  margin: 30px 0 15px;
}

.privacy-policy-container strong {
  color: #000000;
  display: block;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 18px;
}

.privacy-policy-container p {
  margin-bottom: 15px;
  color: #666;
}

.privacy-policy-container section {
  margin-bottom: 40px;
}

@keyframes textFillMove {
  0% { background-position: 0% 50% }
  100% { background-position: 400% 50% }
}

@media (max-width: 600px) {
  .privacy-policy-container {
    flex-grow: 1;
        background-color: white;
        width: 100%;
        margin: 0 auto;
        padding: 40px;
        padding-top: 65px;
        font-family: Arial, sans-serif;
        line-height: 1.6;
        color: #666;
  }

  .privacy-policy-container h1 {
    font-size: 24px;
  }

  .privacy-policy-container h2 {
    font-size: 20px;
  }

  .privacy-policy-container strong {
    font-size: 16px;
  }
}