.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.modal-content {
  background: white;
  border-radius: 16px;
  width: 90%;
  max-width: 1200px;
  max-height: 90vh;
  position: relative;
  overflow: hidden;
  z-index: 1001;
  display: flex;
  flex-direction: column;
}

.modal-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
  overflow-y: auto;
}

.image-section {
  position: relative;
  background: #000;
  height: 100%;
}

.modal-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.details-section {
  padding: 20px;
  overflow-y: auto;
  background: #f8f8f8;
}

.details-section h3 {
  color: #333;
  margin-bottom: 20px;
  font-family: 'Playfair Display', serif;
  font-size: 1.8rem;
  border-bottom: 2px solid #dcc282;
  padding-bottom: 10px;
}

.details-grid {
  display: grid;
  gap: 20px;
}

.detail-item {
  display: flex;
  align-items: center;
  gap: 15px;
  padding: 15px;
  background: white;
  border-radius: 12px;
  transition: transform 0.2s;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.detail-item:hover {
  transform: translateX(5px);
}

.detail-item svg {
  color: #dcc282;
}

.detail-text {
  display: flex;
  flex-direction: column;
}

.detail-label {
  font-size: 0.9em;
  color: #666;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.detail-value {
  font-size: 1.1em;
  color: #333;
  font-weight: 500;
}

.close-button {
  position: absolute;
  top: 15px;
  right: 15px;
  background: rgba(255, 255, 255, 0.9);
  border: none;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  transition: all 0.3s ease;
}

.close-button:hover {
  background: white;
  transform: rotate(90deg);
}

.heart-overlay {
  position: absolute;
  top: 15px;
  right: 15px;
  color: white;
  font-size: 26px;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 3;
  background: rgba(0, 0, 0, 0.3);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
}

.heart-overlay:hover {
  transform: scale(1.1);
  background: rgba(220, 194, 130, 0.3);
}

.heart-overlay.liked {
  color: #dcc282;
}

/* Hide bottom-back-button by default */
.bottom-back-button {
  display: none;
}

@media (max-width: 768px) {
  .modal-overlay {
    padding: 0;
    align-items: flex-start;
  }

  .modal-content {
    width: 100%;
    height: 100vh;
    max-height: 100vh;
    border-radius: 0;
    overflow-y: auto;
    padding: 5px;
  }

  .modal-grid {
    grid-template-columns: 1fr;
    height: auto;
    overflow-y: visible;
  }

  .image-section {
    height: 35vh;
    min-height: 200px;
    position: relative;
    top: 0;
    z-index: 1;
  }

  .modal-image {
    height: 100%;
    object-fit: cover;
  }

  .details-section {
    padding: 5px;
    height: auto;
    overflow-y: visible;
  }

  .details-section h3 {
    font-size: 1.1rem;
    margin-bottom: 5px;
  }

  .details-grid {
    gap: 5px;
  }

  .detail-item {
    padding: 5px;
    font-size: 0.9em;
  }

  .detail-label {
    font-size: 0.8em;
  }

  .detail-value {
    font-size: 1em;
  }

  .heart-overlay {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    font-size: 20px;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
  }

  .close-button {
    display: none;
  }

  /* Show and style bottom-back-button only on mobile */
  .bottom-back-button {
    display: flex;
    position: fixed;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1002;
    background: #dcc282;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 5px 15px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 12px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }

  .bottom-back-button:hover {
    background: #c5ad6e;
  }

  /* Ensure heart overlay is visible */
  .heart-overlay {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 30px;
    height: 30px;
    font-size: 20px;
    z-index: 2;
    background: rgba(0, 0, 0, 0.5);
  }

  .details-section {
    padding-bottom: 80px;
  }
} 