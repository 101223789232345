.mobile-credits-display {
    position: absolute;
    right: 60px;
    top: 50%;
    transform: translateY(-50%);
    background: #dcc282;
    color: white;
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    z-index: 1000;
    display: flex;
    align-items: center;
    gap: 6px;
  }
  
  .mobile-credits-display .coin-icon {
    animation: shine 2s infinite;
  }
  
  @keyframes shine {
    0% { filter: drop-shadow(0 0 2px #ffd700); }
    50% { filter: drop-shadow(0 0 4px #ffd700); }
    100% { filter: drop-shadow(0 0 2px #ffd700); }
  }
  
  /* Optional: Add hover effect */
  .mobile-credits-display:hover {
    background: #c5ad75;
  }
  
  .mobile-credits-display:active {
    transform: translateY(-48%);
  }
  
  @media (max-width: 768px) {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px;
    }
  }
  