body {
  background-color: #f9f9f9;
  color: #333;
}

.profile-container {

  margin: 100px auto;
  padding: 40px 20px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.profile-header {
  background-color: #fff;
  border-radius: 12px;
  padding: 30px;
  margin-bottom: 40px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  border: 1px solid #FF69B4;
}

.profile-info {
  display: flex;
  align-items: center;
}

.profile-avatar {
  margin-right: 30px;
}

.avatar-icon {
  color: #FF69B4;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 20px rgba(255, 105, 180, 0.3);
}

.profile-details h1 {
  color: #FF69B4;
  margin-bottom: 15px;
  font-size: 2.5em;
  font-weight: 700;
  font-family: Merriweather, serif;
  text-shadow: 1px 1px 2px #FF7F50;
}

.profile-details p {
  margin: 8px 0;
  font-size: 1.1em;
  color: #333;
}

.email {
  font-weight: bolder;
}

.user-type {
  font-weight: bold;
  background-color: #FF69B4;
  padding: 5px 10px;
  border-radius: 20px;
  display: inline-block;
  font-size: 0.9em;
  font-family: Merriweather, serif;
}

.bio {
  font-style: italic;
  color: #666;
  margin-top: 15px;
  line-height: 1.6;
}

.vendor-info {
  background-color: var(--secondary-gold);
  color: var(--primary-black);
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 20px;
}

.tabs {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 30px;
}

.tab {
  background-color: #FF69B4;
  color: #fff;
  padding: 12px 25px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin-right: 15px;
  font-family: Merriweather, serif;
}

.tab.active {
  background-color: #FF7F50;
}

.tab:hover {
  background: linear-gradient(135deg, #FF69B4, #FF7F50);
  color: #fff;
}

.tab-icon, .refresh-btn svg {
  margin-right: 10px;
}

.masonry-grid {
  display: flex;
  margin-left: -20px;
  width: auto;
}

.masonry-grid_column {
  padding-left: 20px;
  background-clip: padding-box;
}

.grid-item {
  margin-bottom: 20px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  background-color: #fff;
}

.grid-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
}

.grid-item img {
  width: 100%;
  display: block;
  transition: filter 0.3s ease;
}

.grid-item:hover img {
  filter: brightness(0.9) saturate(1.1);
}

.item-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 15px;
  color: #333;
  transform: translateY(100%);
  transition: transform 0.3s ease;
}

.grid-item:hover .item-overlay {
  transform: translateY(0);
}

.item-details {
  font-size: 14px;
}

.delete-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 105, 180, 0.2);
  color: #FF69B4;
  border: none;
  border-radius: 50%;
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0;
}

.grid-item:hover .delete-button {
  opacity: 1;
}

.delete-button:hover {
  background-color: #FF69B4;
  color: #fff;
}

.delete-button svg {
  margin-right: 0;
}

.hashtags {
  margin-bottom: 10px;
}

.hashtag {
  background-color: #FF69B4;
  color: #fff;
  padding: 4px 8px;
  border-radius: 15px;
  margin-right: 6px;
  font-size: 11px;
  font-weight: bold;
  display: inline-block;
  margin-bottom: 5px;
}

.loading, .error, .not-found {
  text-align: center;
  font-size: 20px;
  margin-top: 82px;
  margin-bottom: 55px;
  color: #FF69B4;
  font-weight: bold;
  background: #fff;
}

.likes-placeholder {
  text-align: center;
  padding: 40px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  margin-top: 20px;
}

.likes-placeholder p {
  font-size: 18px;
  color: #FF69B4;
}
.load-more-button {
  background-color: #FF69B4;
  color: #fff;
  padding: 12px 25px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
}
.tab .heart-icon {
  fill: #FF69B4;
  stroke-width: 2px;
}


@media screen and (max-width: 768px) {
  .profile-details h2 {
    font-size: 1.8em;
  }
  .delete-button svg {
    position: absolute;
    top: 40px;
    right: 10px;
  }
  .tab {
    font-size: 14px;
    padding: 8px 12px;
  }
  .masonry-grid {
    display: block;
  }
  .profile-info {
    display: block;
    align-items: center;
  }
  .masonry-grid_column {
    width: 100% !important;
  }
 
  .profile-header {
    background-color: #fff;
    border-radius: 12px;
    padding: 15px;
    margin-bottom: 20px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
    border: 1px solid #FF69B4;
    font-size: 14px;
  }
  .profile-avatar {
    margin: 15px 0;
    text-align: center;
  }
}