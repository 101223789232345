.credits-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.credits-modal {
  background: #dcc282;
  border-radius: 15px;
  padding: 30px;
  width: 90%;
  max-width: 400px;
  position: relative;
  text-align: center;
  transform-origin: center center;
  transition: all 0.5s ease;
}

.credits-modal.expanding {
  height: 70vh;
  max-height: 700px;
  background: #dcc282;
}

.credits-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  transition: all 0.5s ease;
}

.credits-modal-content.expanding {
  opacity: 0;
  transform: translateY(-20px);
}

.payment-form {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  opacity: 0;
  transform: translateY(100%);
  transition: all 0.5s ease;
  border-radius: 15px;
  overflow: hidden;
}

.payment-form.visible {
  opacity: 1;
  transform: translateY(0);
}

.payment-header {
  background: #dcc282;
  padding: 30px 20px;
  text-align: center;
}

.credits-amount {
  font-size: 48px;
  font-weight: bold;
  margin: 15px 0;
}

.credits-input {
  background: #dcc282;
  margin: 30px auto;
  padding: 10px 20px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 200px;
}

.payment-content {
  background: white;
  flex: 1;
  padding: 20px;
  border-radius: 20px;
  margin-top: -20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.credits-modal h3 {
  color: black;
  font-size: 24px;
  margin: 0;
}

.credits-count {
  font-size: 72px;
  font-weight: bold;
  color: black;
  margin: 20px 0;
}

.credits-buttons {
  display: flex;
  flex-direction: row;
  gap: 10px;
  width: 100%;
}

.credits-buttons button {
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  border: none;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pricing-button {
  background: black;
  color: white;
  transition: all 0.3s ease;
}

.pricing-button:hover {
  background: black;
  color: white;
  border: 2px solid #ffffff !important;
}

.get-credits-button {
  color: white;
  border: none !important;
}

/* Button styles */
button {
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 20px;
  cursor: pointer;
  font-size: 1.5em;
  transition: background-color 0.2s ease;
  
  align-self: center;
}

button:disabled {
  background-color: #cccccc;
}


.close-modal-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: black;
  cursor: pointer;
  padding: 5px;
  opacity: 0.7;
}

.close-modal-button:hover {
  opacity: 1;
}
.payment-methods {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
  margin: 20px 0;
}

.payment-method {
  background: black;
  color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.payment-method.selected {
  background: white;
  color: black;
  border: 2px solid black;
}

@media (max-width: 768px) {
  .credits-modal {
    width: 75%;
    padding: 15px;
    top: 65px;
  }

  .credits-modal h3 {
    font-size: 20px;
  }

  .credits-count {
    font-size: 72px;
    margin: 15px 0;
  }

  .credits-buttons button {
    padding: 15px;
    font-size: 16px;
  }
  .close-modal-button {
    position: absolute;
    top: 9px;
    background: none;
    border: none;
    font-size: 24px;
    color: black;
    cursor: pointer;
    padding: 5px;
    opacity: 0.7;
  }
  .credits-modal.expanding {
    height: 80vh;
    max-height: 700px;
    background: #dcc282;
  }
  .payment-methods {
    grid-template-columns: repeat(2, 1fr);
    gap: 8px;
  }

  .payment-method {
    padding: 12px;
    font-size: 14px;
  }
}
.pay-button {
  width: 100%;
  padding: 15px;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
}

.pay-button:hover {
  background: black;
}

.credits-price {
  font-size: 24px;
  color: black;
  margin-top: 10px;
}

.close-payment-form {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  color: black;
  cursor: pointer;
  padding: 5px;
  opacity: 0.7;
  z-index: 10;
  transition: opacity 0.3s ease;
}

.close-payment-form:hover {
  opacity: 1;
}

@media (max-width: 768px) {
  .close-payment-form {
    top: 8px;
    right: 8px;
    font-size: 20px;
  }
}
.payment-form {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}

.error-message {
  padding: 8px;
  border-radius: 4px;
  background-color: rgba(255, 0, 0, 0.1);
}

.pay-button {
  transition: all 0.2s ease;
}

.pay-button:not(:disabled):hover {
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.visualization-button.disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* Create a fixed wrapper for credits */
.credits-wrapper {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
}

.credits-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.credits-modal {
  background: white;
  border-radius: 15px;
  padding: 20px;
  width: 80%;
  max-width: 700px;
  position: relative;
  text-align: center;
  transform-origin: center center;
  transition: all 0.5s ease;
}

.credits-modal.expanding {
  height: 80vh;
  max-height: 800px;
}

.pricing-plans-container {
  padding: 10px;
}

.pricing-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 15px;
  margin-top: 20px;
}

.pricing-card {
  background: white;
  border: 1px solid #dcc282;
  border-radius: 12px;
  padding: 15px;
  text-align: center;
  position: relative;
  transition: all 0.3s ease;
  font-size: 0.9em;
}

.pricing-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.1);
}

.pricing-card.popular {
  border: 2px solid #dcc282;
  transform: scale(1.05);
}

.popular-badge {
  position: absolute;
  top: -12px;
  left: 50%;
  transform: translateX(-50%);
  background: #dcc282;
  color: white;
  padding: 5px 6px;
  border-radius: 20px;
  font-size: 9px;

  font-weight: bold;
}

.pricing-card h4 {
  margin: 0;
  color: #333;
  font-size: 18px;
}

.credits-amount {
  font-size: 24px;
  font-weight: bold;
  color: #dcc282;
  margin: 10px 0;
}

.plan-price {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0;
  color: black;

}

.free-text {
  font-size: 14px;
  color: #666;
  margin-left: 5px;
  display: inherit;
}

.plan-description {
  color: #666;
  margin: 10px 0;
  font-size: 13px;
}

.select-plan-button {
  width: 100%;
  padding: 8px;
  background: #dcc282;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 14px;
}

.select-plan-button:disabled {
  background: #cccccc;
  cursor: not-allowed;
}

.select-plan-button:not(:disabled):hover {
  background: black;
}

/* Existing styles remain the same */

.error-message {
  background-color: #fee;
  color: #c00;
  padding: 15px;
  margin: 15px;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.sign-in-button {
  background: #dcc282;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: bold;
  transition: all 0.3s ease;
}

.sign-in-button:hover {
  background: black;
}
.payment-back-button{
    background: #dcc282;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 42px;
    position: relative;
    display: block;
    width: 32%;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .credits-modal {
    width: 95%;
    max-height: 85vh !important;
    padding: 15px;
    margin: 20px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .pricing-grid {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    padding-bottom: 20px;
  }

  .pricing-card {
    padding: 10px;
    font-size: 0.85em;
  }

  .credits-amount {
    font-size: 18px;
    margin: 5px 0;
  }

  .plan-price {
    font-size: 16px;
    margin: 5px 0;
  }

  .plan-description {
    font-size: 12px;
    margin: 5px 0;
  }

  .select-plan-button {
    padding: 6px;
    font-size: 12px;
  }

  .payment-section {
    padding-bottom: 30px;
  }

  .selected-plan-summary {
    margin-bottom: 15px;
  }

  .credits-modal-overlay {
    align-items: flex-start;
    padding-top: 20px;
  }
}

/* Success State */
.payment-success {
  text-align: center;
  padding: 30px;
  animation: fadeIn 0.3s ease;
}

.success-icon {
  font-size: 48px;
  color: #4CAF50;
  margin-bottom: 20px;
  animation: scaleIn 0.5s ease;
}

/* Error State */
.payment-error-container {
  text-align: center;
  padding: 30px;
  animation: fadeIn 0.3s ease;
}

.error-icon {
  font-size: 48px;
  margin-bottom: 20px;
  animation: shake 0.5s ease;
}

.retry-button {
  background: #dcc282;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.3s ease;
}

.retry-button:hover {
  background: black;
  transform: translateY(-1px);
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes scaleIn {
  from { transform: scale(0); }
  to { transform: scale(1); }
}

@keyframes shake {
  0%, 100% { transform: translateX(0); }
  25% { transform: translateX(-10px); }
  75% { transform: translateX(10px); }
}