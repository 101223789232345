.kanban-container {
  @apply space-y-4;
}

.kanban-controls {
  @apply flex items-center gap-3;
}

.add-task-button {
  @apply flex items-center px-3 py-2 bg-pink-600 text-white rounded-lg hover:bg-pink-700 transition-colors;
}

.kanban-board {
  @apply flex gap-4 h-[calc(100vh-28rem)] px-1 pt-1;
}

.kanban-column {
  @apply flex-1 min-w-[18rem] max-w-xs flex flex-col bg-gray-50/80 backdrop-blur-sm rounded-lg p-3;
}

.column-header {
  @apply font-semibold text-gray-700 mb-3 flex items-center justify-between;
}

.task-count {
  @apply bg-gray-200 text-gray-600 px-2 py-0.5 rounded text-sm;
}

.task-list {
  @apply relative flex-1 overflow-y-auto rounded-lg transition-colors duration-200;
}

.task-card {
  @apply bg-white rounded-lg shadow-sm border border-gray-200 transition-all duration-75 ease-in-out;
}

.task-card-dragging {
  @apply shadow-lg ring-2 ring-pink-500 ring-opacity-50 cursor-grabbing rotate-1;
}

.task-card-content {
  @apply p-3;
}

.task-title {
  @apply font-medium text-gray-800 mb-1.5 text-sm group-hover:text-pink-700 transition-colors;
}

.task-description {
  @apply text-xs text-gray-600 mb-2;
}

.task-metadata {
  @apply flex items-center justify-between text-xs;
}

.task-date {
  @apply flex items-center text-gray-500;
}

.task-priority {
  @apply px-1.5 py-0.5 rounded-full text-xs;
}

input {
  @apply text-black; /* Use Tailwind's @apply directive */
}

textarea {
  @apply text-black; /* Use Tailwind's @apply directive */
}