/* Explore page container */
.explore-page {
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  padding: 40px 20px;
  position: relative;
  top: 90px;
}

/* Page title styling */
.explore-page h1 {
  font-family: 'Playfair Display', serif;
  color: #dcc282;
  font-size: 2.5rem;
  margin-bottom: 40px;
  text-align: center;
  position: relative;
  padding-bottom: 15px;
}

.explore-page h1::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100px;
  height: 2px;
  background: linear-gradient(90deg, transparent, #dcc282, transparent);
}
.explore-icon{
  @apply w-3.5 h-3.5 text-white/70;
}

/* Masonry grid styling */
.my-masonry-grid {
  display: flex;
  margin-left: -20px;
  width: auto;
}

.my-masonry-grid_column {
  padding-left: 20px;
  background-clip: padding-box;
}

/* Picture item styling */
.picture-item {
  margin-bottom: 20px;
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-container {
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
}

.picture {
  width: 94%;
  height: auto;
  transition: transform 0.5s ease;
  border-radius: 12px;
}

/* Hover effects */
.image-container:hover .picture {
  transform: scale(1.05);
}

.image-container:hover::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.4)
  );
  border-radius: 12px;
}

/* Heart icon styling */
.heart-overlay {
  position: absolute;
  top: 15px;
  right: 15px;
  color: white;
  font-size: 26px;
  cursor: pointer;
  transition: all 0.3s ease;
  z-index: 3;
  background: rgba(0, 0, 0, 0.3);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(4px);
}

.heart-overlay:hover {
  transform: scale(1.1);
  background: rgba(220, 194, 130, 0.3);
}

.heart-overlay.liked {
  color: #dcc282;
  animation: heartPop 0.3s ease;
}

/* Animation for heart icon */
@keyframes heartPop {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .my-masonry-grid {
    margin-left: -15px;
  }
  .my-masonry-grid_column {
    padding-left: 15px;
  }
}

@media (max-width: 768px) {
  .explore-page h1 {
    font-size: 2rem;
    margin-bottom: 30px;
  }
  
  .my-masonry-grid {
    margin-left: -10px;
  }
  
  .my-masonry-grid_column {
    padding-left: 10px;
  }
  
  .picture-item {
    margin-bottom: 15px;
  }
}

/* Existing styles remain the same, add these new mobile-specific styles */

@media (max-width: 768px) {
  .explore-page {
    padding: 20px 10px;
  }

  .explore-page h1 {
    font-size: 1.8rem;
    margin-bottom: 20px;
  }

  .my-masonry-grid {
    margin-left: -8px; /* Tighter spacing */
  }

  .my-masonry-grid_column {
    padding-left: 8px;
  }

  .picture-item {
    margin-bottom: 8px;
    border-radius: 8px;
  }

  .image-container {
    border-radius: 8px;
    box-shadow: none; /* Remove shadow for cleaner mobile look */
  }

  .picture {
    border-radius: 8px;
  }

  /* Pinterest-style image container */
  .image-container:hover .picture {
    transform: none; /* Disable hover effect on mobile */
  }

  .image-container:hover::after {
    background: none; /* Remove hover overlay on mobile */
  }

  /* Adjust heart icon for mobile */
  .heart-overlay {
    top: auto;
    bottom: 10px;
    right: 10px;
    width: 36px;
    height: 36px;
    font-size: 20px;
    background: rgba(0, 0, 0, 0.5);
  }
}

/* Add Pinterest-style loading for mobile */
.mobile-loading-spinner {
  display: none;
}

@media (max-width: 768px) {
  .mobile-loading-spinner {
    display: block;
    text-align: center;
    padding: 20px 0;
    color: #dcc282;
  }
}

/* Modal styles for mobile */
@media (max-width: 768px) {
  .modal-overlay {
    padding: 10px !important;
    background: rgba(0, 0, 0, 0.8) !important;
  }

  .modal-content {
    top:15px;
    width: 95% !important;
    max-height: 80vh !important;
    margin: 10% auto !important;
    padding: 15px !important;
    border-radius: 12px !important;
  }

  .modal-image {
    max-height: 50vh !important;
    object-fit: contain !important;
    border-radius: 8px !important;
  }

  .modal-details {
    padding: 10px 0 !important;
    margin-top: 10px !important;
  }

  .modal-close {
    top: 5px !important;
    right: 5px !important;
    font-size: 24px !important;
  }

  .modal-actions {
    padding: 10px 0 !important;
    gap: 10px !important;
  }
}
