.dashboard-grid {
    @apply space-y-4;
  }
  
  .metrics-grid {
    @apply grid grid-cols-4 gap-3;
  }
  
  .content-grid {
    @apply grid grid-cols-2 gap-4;
  }
  
  .dashboard-card {
    @apply bg-white rounded-xl shadow-sm border border-gray-100 overflow-hidden;
  }
  
  .card-header {
    @apply px-4 py-3 border-b border-gray-100 flex items-center justify-between;
  }
  
  .card-title {
    @apply flex items-center space-x-2;
  }
  
  .card-content {
    @apply p-6;
  }
  
  .card-content-compact {
    @apply p-3;
  }
  
  .metric-card {
    @apply bg-white rounded-xl shadow-sm border border-gray-100 p-6;
  }
  
  .metric-content {
    @apply flex items-center space-x-4;
  }
  
  .metric-icon {
    @apply p-3 rounded-lg;
  }
  
  .metric-label {
    @apply text-sm text-gray-500;
  }
  
  .metric-value {
    @apply text-2xl font-bold text-gray-800;
  }