.terms-of-service-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    max-width: 100%;
    overflow-x: hidden;
    width: 100%;
    padding: 0;
}

.terms-of-service-container {
    flex-grow: 1;
    background-color: white;
    width: 100%;
    margin: 0 auto;
    padding: 100px;
    padding-top: 100px;
    font-family: Arial, sans-serif;
    line-height: 1.6;
    color: #666;
}

.terms-of-service-container h1 {
    text-align: center;
    background-image: linear-gradient(
        135deg,
        #6d0fb5 0%,
        #a12fbd 25%,
        #e12b42 50%,
        #b74f72 75%,
        #51239f 100%
    );
    background-size: 400% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    animation: textFillMove 3s linear infinite;
    font-size: 32px;
    margin-bottom: 10px;
    font-weight: 600;
}

.terms-of-service-container .effective-date {
    font-style: italic;
    color: #666;
    text-align: center;
    margin-bottom: 40px;
}

.terms-of-service-container h2 {
    color: #333;
    font-size: 24px;
    margin: 30px 0 15px;
}

.terms-of-service-container strong {
    color: #a12fbd;
    display: block;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 18px;
}

.terms-of-service-container p {
    margin-bottom: 15px;
    color: #666;
}

.terms-of-service-container section {
    margin-bottom: 40px;
}

.terms-of-service-container .list {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 20px;
    color: #666;
}

.terms-of-service-container .list li {
    margin-bottom: 8px;
}

@keyframes textFillMove {
    0% { background-position: 0% 50% }
    100% { background-position: 400% 50% }
}

@media (max-width: 600px) {
    .terms-of-service-container {
        flex-grow: 1;
        background-color: white;
        width: 100%;
        margin: 0 auto;
        padding: 40px;
        padding-top: 65px;
        font-family: Arial, sans-serif;
        line-height: 1.6;
        color: #666;
    }
    

    .terms-of-service-container h1 {
        font-size: 24px;
    }

    .terms-of-service-container h2 {
        font-size: 20px;
    }

    .terms-of-service-container strong {
        font-size: 16px;
    }
}