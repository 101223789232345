/* Navigation list layout */
.nav-list {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 0;
  padding: 0;
  list-style: none;
}

/* Base styles for nav links */
.nav-link {
  color: white;
  text-decoration: none;
  padding: 0.5rem 0.75rem;
  transition: all 0.3s ease;
  background-clip: text;
  -webkit-background-clip: text;
  font-size: 1rem;
}

/* Gradient text animation on hover */
.nav-link:hover {
  color: transparent;
  background-image: linear-gradient(
    135deg,
    #FF7F8E 0%,
    #FFD6B6 50%,
    #E7D8F0 100%
  );
  background-size: 200% auto;
  animation: gradientShift 2s linear infinite;
  -webkit-background-clip: text;
  background-clip: text;
}

/* Logo text styles */
.logo-text {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-weight: 600;
  font-size: 1.5rem;
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
  background-clip: text;
  -webkit-background-clip: text;
}

.logo-text:hover {
  color: transparent;
  background-image: linear-gradient(
    135deg,
    #FF7F8E 0%,
    #FFD6B6 50%,
    #E7D8F0 100%
  );
  background-size: 200% auto;
  animation: gradientShift 2s linear infinite;
  -webkit-background-clip: text;
  background-clip: text;
}

/* Profile icon hover effect */
.profile-icon {
  transition: all 0.3s ease;
  padding: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.profile-icon svg {
  stroke: white;
  transition: all 0.3s ease;
  width: 28px;
  height: 28px;
}

.profile-icon:hover svg {
  stroke: url(#gradient);
}

/* Gradient animation keyframes */
@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Keep existing dropdown styles */
.dropdown-link {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  transition: all 0.2s ease;
  color: #4A5568;
  font-size: 0.95rem;
}

.dropdown-link:hover {
  background: linear-gradient(135deg, rgba(255, 127, 142, 0.15), rgba(255, 214, 182, 0.15));
  color: #1A202C;
  transform: translateX(4px);
}

.nav-button {
  background: transparent;
  color: #4A5568;
  font-size: 0.95rem;
  font-weight: 500;
  transition: all 0.2s ease;
  width: 100%;
  padding: 0.75rem 1rem;
  text-align: left;
  display: flex;
  align-items: center;
  border: none;
  cursor: pointer;
}

.nav-button:hover {
  background: linear-gradient(135deg, rgba(255, 127, 142, 0.15), rgba(255, 214, 182, 0.15));
  color: #1A202C;
  transform: translateX(4px);
}

/* Add smooth transition for dropdown */
.dropdown-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.dropdown-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 200ms, transform 200ms;
}

.dropdown-exit {
  opacity: 1;
  transform: translateY(0);
}

.dropdown-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 200ms, transform 200ms;
}

/* Title styles with gradient hover */
.title {
  color: white;
  transition: all 0.3s ease;
  background-clip: text;
  -webkit-background-clip: text;
}

